import React from 'react';
import { motion } from 'framer-motion';
import { Search, Pencil, Headphones } from 'lucide-react';
import { useInView } from 'react-intersection-observer';

const steps = [
  {
    number: '01',
    icon: Search,
    title: 'Analyse des besoins',
    description: 'Étude approfondie de vos objectifs et contraintes pour proposer des solutions adaptées.',
  },
  {
    number: '02',
    icon: Pencil,
    title: 'Conception sur mesure',
    description: 'Développement itératif avec validation à chaque étape clé du projet.',
  },
  {
    number: '03',
    icon: Headphones,
    title: 'Livraison et support',
    description: 'Mise en production et accompagnement continu pour garantir votre succès.',
  },
];

export const Process = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section className="py-20" id="process">
      <div className="container mx-auto px-4">
        <h2 className="font-outfit text-3xl md:text-4xl font-bold text-center mb-16">
          Notre Processus
        </h2>
        <div
          ref={ref}
          className="relative"
        >
          <div className="hidden md:block absolute top-1/2 left-0 w-full h-0.5 bg-gray-200 -translate-y-1/2" />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.map((step, index) => (
              <motion.div
                key={step.number}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="relative bg-white p-6 rounded-xl shadow-lg"
              >
                <div className="absolute -top-4 left-1/2 -translate-x-1/2 w-8 h-8 bg-gradient-to-r from-blue-600 to-violet-600 rounded-full flex items-center justify-center text-white text-sm font-bold">
                  {step.number}
                </div>
                <div className="pt-4">
                  <step.icon className="w-8 h-8 text-blue-600 mb-4" />
                  <h3 className="font-outfit text-xl font-semibold mb-2">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};