import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

export const Header = () => {
  const scrollToServices = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const servicesSection = document.getElementById('services');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="relative min-h-screen flex items-center">
      {/* Background gradients */}
      <div className="absolute inset-0 bg-gradient-radial from-[#22261e] via-transparent to-[#080807] opacity-50"></div>
      
      {/* Content */}
      <div className="container mx-auto px-4 py-16 md:py-24 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6">
              Boostez votre entreprise avec <span className="text-[#D3FFCA]">l'intelligence artificielle</span>
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Certitech, votre partenaire pour intégrer l'IA et créer des outils numériques sur mesure
            </p>
            <a
              href="#services"
              onClick={scrollToServices}
              className="group inline-flex items-center px-6 py-3 text-lg font-semibold text-white rounded-lg bg-gradient-to-r from-blue-600 to-violet-600 hover:from-blue-700 hover:to-violet-700 transition-all duration-200"
            >
              Découvrir nos services
              <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </a>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="hidden lg:block"
          >
            <img
              src="https://images.unsplash.com/photo-1677442136019-21780ecad995"
              alt="AI Technology Illustration"
              className="w-full h-auto rounded-2xl shadow-2xl"
            />
          </motion.div>
        </div>
      </div>
    </header>
  );
};