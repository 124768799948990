import React from 'react';
import { Navigation } from './components/Navigation';
import { Header } from './components/Header';
import { Services } from './components/Services';
import { Process } from './components/Process';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';

function App() {
  return (
    <div className="min-h-screen bg-gray-900">
      <Navigation />
      <Header />
      <Services />
      <Process />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;