import React from 'react';
import { motion } from 'framer-motion';
import { BookOpen, Globe, Wrench } from 'lucide-react';
import { useInView } from 'react-intersection-observer';

const services = [
  {
    icon: BookOpen,
    title: 'Formation IA',
    description: 'Formez vos équipes aux dernières technologies d\'IA avec nos programmes personnalisés et notre approche pratique orientée résultats.',
  },
  {
    icon: Globe,
    title: 'Création de sites web',
    description: 'Développement de sites web modernes, performants et responsifs qui reflètent l\'identité de votre entreprise.',
  },
  {
    icon: Wrench,
    title: 'Outils numériques personnalisés',
    description: 'Création d\'outils sur mesure pour optimiser vos processus et augmenter votre productivité.',
  },
];

export const Services = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section className="py-20 bg-gray-50" id="services">
      <div className="container mx-auto px-4">
        <h2 className="font-outfit text-3xl md:text-4xl font-bold text-center mb-16">
          Nos Services
        </h2>
        <div
          ref={ref}
          className="grid grid-cols-1 md:grid-cols-3 gap-8"
        >
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="w-12 h-12 bg-gradient-to-r from-blue-600 to-violet-600 rounded-lg flex items-center justify-center mb-6">
                <service.icon className="w-6 h-6 text-white" />
              </div>
              <h3 className="font-outfit text-xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};